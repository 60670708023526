import React, { useState, useEffect, useRef } from 'react';
import { Send, ArrowRight } from 'lucide-react';
import { getCoachingMessage, resetCoachingState } from '../services/CoachingService';
import type { Habit, Goal } from '../types';

interface CoachProps {
  habits: Habit[];
  goals: Goal[];
  userName: string;
  onContinue: () => void;
  onAddHabit?: (habit: Omit<Habit, 'id' | 'completed'>) => void;
  onAddGoal?: (goal: Omit<Goal, 'id' | 'current'>) => void;
}

interface Message {
  type: 'user' | 'coach';
  content: string;
}

export const Coach: React.FC<CoachProps> = ({ 
  habits, 
  goals, 
  userName, 
  onContinue,
  onAddHabit,
  onAddGoal
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [showContinue, setShowContinue] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    resetCoachingState();
    const response = getCoachingMessage('');
    setMessages([{ 
      type: 'coach', 
      content: response.message
    }]);
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = input.trim();
    setInput('');
    
    // Add user message
    setMessages(prev => [...prev, { type: 'user', content: userMessage }]);

    // Get coaching response
    const response = getCoachingMessage(userMessage);
    
    // Add coach response
    setMessages(prev => [...prev, { type: 'coach', content: response.message }]);

    // If a new habit was created, add it
    if (response.newHabit && onAddHabit) {
      onAddHabit(response.newHabit);
    }

    // If a new goal was created, add it
    if (response.newGoal && onAddGoal) {
      onAddGoal(response.newGoal);
    }

    // Show continue button if indicated in the response
    if (response.showContinue) {
      setShowContinue(true);
    }
  };

  return (
    <div className="min-h-screen bg-mindcally-dark-background flex flex-col">
      <div className="flex-1 overflow-hidden flex flex-col">
        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.type === 'user' ? 'justify-end' : 'justify-start'
              }`}
            >
              <div
                className={`max-w-[80%] rounded-lg p-3 ${
                  message.type === 'user'
                    ? 'bg-mindcally-lime text-mindcally-navy'
                    : 'bg-mindcally-dark-surface text-white'
                }`}
              >
                {message.content}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>

        <div className="p-4 bg-mindcally-navy">
          <form onSubmit={handleSubmit} className="mb-4">
            <div className="flex gap-2">
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                placeholder="Type your response..."
                className="flex-1 bg-mindcally-dark-surface text-white rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-mindcally-lime"
              />
              <button
                type="submit"
                disabled={!input.trim()}
                className="bg-mindcally-lime text-mindcally-navy px-4 py-2 rounded-lg hover:bg-mindcally-light-hover transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <Send size={20} />
              </button>
            </div>
          </form>

          {showContinue && (
            <button
              onClick={onContinue}
              className="w-full flex items-center justify-center gap-2 bg-mindcally-lime text-mindcally-navy py-3 px-6 rounded-lg hover:bg-mindcally-light-hover transition-colors font-semibold"
            >
              Continue to Dashboard
              <ArrowRight size={20} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};