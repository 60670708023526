import React from 'react';
import { Home, BarChart2, Settings, Play, Users, Menu } from 'lucide-react';
import type { ViewType } from '../types';

interface NavbarProps {
  currentView: ViewType;
  onViewChange: (view: ViewType) => void;
}

export const Navbar: React.FC<NavbarProps> = ({ currentView, onViewChange }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const handleViewChange = (view: ViewType) => {
    onViewChange(view);
    setIsMenuOpen(false);
  };

  return (
    <nav className="fixed top-0 left-0 right-0 bg-mindcally-navy border-b border-mindcally-dark-surface z-50 px-4">
      <div className="max-w-md mx-auto py-2 flex justify-between items-center relative">
        <div className="text-mindcally-lime font-semibold text-lg">MindCally</div>
        
        <div className="relative">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="p-2 text-white hover:text-mindcally-lime transition-colors"
            aria-label="Toggle menu"
          >
            <Menu size={24} />
          </button>

          {isMenuOpen && (
            <>
              <div 
                className="fixed inset-0 bg-transparent"
                onClick={() => setIsMenuOpen(false)}
              />
              <div className="absolute right-0 top-full mt-2 w-48 bg-mindcally-dark-surface rounded-lg shadow-lg overflow-hidden">
                <div className="py-1">
                  {[
                    { view: 'home' as const, icon: Home, label: 'Home' },
                    { view: 'stats' as const, icon: BarChart2, label: 'Stats' },
                    { view: 'pause' as const, icon: Play, label: 'Pause' },
                    { view: 'spaces' as const, icon: Users, label: 'Spaces' },
                    { view: 'settings' as const, icon: Settings, label: 'Settings' }
                  ].map(({ view, icon: Icon, label }) => (
                    <button
                      key={view}
                      onClick={() => handleViewChange(view)}
                      className={`w-full flex items-center gap-3 px-4 py-2 text-sm transition-colors ${
                        currentView === view
                          ? 'text-mindcally-lime bg-mindcally-dark-background'
                          : 'text-white hover:bg-mindcally-dark-background hover:text-mindcally-lime'
                      }`}
                    >
                      <Icon size={18} />
                      <span>{label}</span>
                    </button>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};