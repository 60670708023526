import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { format } from 'date-fns';

interface BannerProps {
  userName: string;
  completionRate: number;
  totalHabits: number;
  completedHabits: number;
}

export const Banner: React.FC<BannerProps> = ({
  userName,
  completionRate,
  totalHabits,
  completedHabits,
}) => {
  const { theme } = useTheme();

  return (
    <div className="bg-mindcally-lime dark:bg-mindcally-navy p-6 pb-8 rounded-b-[2rem] relative">
      <div className="flex justify-center mb-4">
        <div className="text-mindcally-navy dark:text-mindcally-lime font-bold text-xl">
          MindCally
        </div>
      </div>

      <div className="space-y-1">
        <p className="text-sm text-mindcally-navy dark:text-mindcally-lime font-medium">
          {format(new Date(), 'EEE, d MMMM yyyy')}
        </p>
        <h1 className="text-2xl font-bold text-mindcally-navy dark:text-white">
          Hello, <span className="text-mindcally-navy/80 dark:text-mindcally-lime">{userName}</span>!
        </h1>
      </div>

      <div className="mt-6 flex items-center justify-between">
        <div className="relative w-32 h-32">
          <svg className="w-full h-full transform -rotate-90">
            <circle
              className="text-mindcally-navy/20 dark:text-mindcally-lime/20"
              strokeWidth="8"
              stroke="currentColor"
              fill="transparent"
              r="40"
              cx="64"
              cy="64"
            />
            <circle
              className="text-mindcally-navy dark:text-mindcally-lime transition-all duration-300"
              strokeWidth="8"
              strokeDasharray={251.2}
              strokeDashoffset={251.2 * (1 - completionRate / 100)}
              strokeLinecap="round"
              stroke="currentColor"
              fill="transparent"
              r="40"
              cx="64"
              cy="64"
            />
          </svg>
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-2xl font-bold text-mindcally-navy dark:text-white">
              {completionRate}%
            </span>
          </div>
        </div>
        <div className="text-mindcally-navy dark:text-white">
          <p className="text-xl font-bold">
            {completedHabits} of {totalHabits} habits
          </p>
          <p className="text-mindcally-navy/80 dark:text-mindcally-lime">
            completed today!
          </p>
        </div>
      </div>
    </div>
  );
};