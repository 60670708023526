import { initializeApp, FirebaseOptions } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyDdDbtywrw_zlyPcNNMdrzer7lO6b1MibE",
  authDomain: "mindcally-1e069.firebaseapp.com",
  projectId: "mindcally-1e069",
  storageBucket: "mindcally-1e069.firebasestorage.app",
  messagingSenderId: "424945442830",
  appId: "1:424945442830:web:8052e61b06abe202d41c5f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log('Firebase initialized successfully');

// Initialize Firestore
const db = getFirestore(app);

// Initialize Auth with persistence
const auth = getAuth(app);

// Set persistence
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log('Auth persistence set to local');
  })
  .catch((error) => {
    console.error('Error setting auth persistence:', error);
  });

export { db, auth };