import React from 'react';
import { format, subDays, isSameDay } from 'date-fns';

interface DateStripProps {
  selectedDate: Date;
  onDateSelect: (date: Date) => void;
}

export const DateStrip: React.FC<DateStripProps> = ({ selectedDate, onDateSelect }) => {
  const today = new Date();
  // Generate dates from 6 days ago up to today
  const dates = Array.from({ length: 7 }, (_, i) => subDays(today, 6 - i));

  return (
    <div className="flex space-x-4 overflow-x-auto pb-2">
      {dates.map((date) => {
        const isSelected = isSameDay(date, selectedDate);
        const isToday = isSameDay(date, today);
        return (
          <button
            key={date.toISOString()}
            onClick={() => onDateSelect(date)}
            className={`flex flex-col items-center min-w-[60px] py-2 px-4 rounded-lg transition-colors
              ${isSelected 
                ? 'bg-mindcally-lime text-mindcally-navy dark:text-mindcally-navy' 
                : 'hover:bg-mindcally-light-surface dark:hover:bg-mindcally-dark-surface'
              }
              ${!isSelected && isToday 
                ? 'text-mindcally-lime dark:text-mindcally-lime font-semibold' 
                : 'text-mindcally-navy dark:text-mindcally-lime'
              }
            `}
          >
            <span className="text-sm font-medium">{format(date, 'dd')}</span>
            <span className="text-xs">{format(date, 'MMM')}</span>
          </button>
        );
      })}
    </div>
  );
};