import React from 'react';
import { Check } from 'lucide-react';
import { Spinner } from './Spinner';

interface CheckInButtonProps {
  isCompleted: boolean;
  isLoading: boolean;
  onClick: () => void;
  disabled: boolean;
}

export const CheckInButton: React.FC<CheckInButtonProps> = ({
  isCompleted,
  isLoading,
  onClick,
  disabled
}) => {
  return (
    <div
      onClick={onClick}
      className={`w-6 h-6 rounded-full flex items-center justify-center transition-all duration-300
        ${isCompleted
          ? 'bg-mindcally-lime text-mindcally-navy'
          : disabled
          ? 'bg-gray-200 dark:bg-mindcally-dark-background cursor-not-allowed'
          : 'border-2 border-gray-300 dark:border-gray-600 hover:border-mindcally-lime cursor-pointer'
        }`}
      role="button"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && !disabled && !isLoading && !isCompleted) {
          onClick();
        }
      }}
      aria-label={isCompleted ? "Already checked in" : "Check in for today"}
    >
      {isLoading ? (
        <Spinner size={14} />
      ) : isCompleted ? (
        <Check size={16} className="text-mindcally-navy" />
      ) : null}
    </div>
  );
};