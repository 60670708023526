import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Trophy } from 'lucide-react';
import { 
  format, 
  startOfMonth, 
  endOfMonth, 
  addMonths, 
  subMonths,
  isSameMonth,
  parseISO,
  isAfter,
  startOfDay,
  isSameDay
} from 'date-fns';
import { HabitProgress } from './HabitProgress';
import type { Habit, HabitCompletion, Goal, GoalProgress } from '../types';

interface StatsViewProps {
  habits: Habit[];
  habitHistory: HabitCompletion[];
  goals: Goal[];
  goalProgress: GoalProgress[];
  onBack: () => void;
}

export const StatsView: React.FC<StatsViewProps> = ({ 
  habits, 
  habitHistory, 
  goals,
  goalProgress
}) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const calculateHabitProgress = () => {
    const monthlyHistory = habitHistory.filter(h => {
      const date = parseISO(h.date);
      return isSameMonth(date, selectedMonth);
    });

    let totalCompleted = monthlyHistory.filter(h => h.completed).length;

    if (isSameMonth(selectedMonth, new Date())) {
      totalCompleted += habits.filter(habit => habit.completed).length;
    }

    const daysInMonth = isSameMonth(selectedMonth, new Date()) 
      ? new Date().getDate() 
      : endOfMonth(selectedMonth).getDate();
    const totalPossible = habits.length * daysInMonth;

    return totalPossible > 0 ? Math.round((totalCompleted / totalPossible) * 100) : 0;
  };

  const calculateGoalProgress = () => {
    if (goals.length === 0) return 0;

    const totalProgress = goals.reduce((acc, goal) => {
      // Use the goal's current value directly
      const progress = Math.min((goal.current / goal.target) * 100, 100);
      return acc + progress;
    }, 0);

    return Math.round(totalProgress / goals.length);
  };

  const habitProgress = calculateHabitProgress();
  const goalProgressPercentage = calculateGoalProgress();

  const handlePreviousMonth = () => {
    setSelectedMonth(subMonths(selectedMonth, 1));
  };

  const handleNextMonth = () => {
    const newDate = addMonths(selectedMonth, 1);
    if (!isAfter(newDate, new Date())) {
      setSelectedMonth(newDate);
    }
  };

  // Separate completed and in-progress goals
  const { completedGoals, inProgressGoals } = goals.reduce(
    (acc, goal) => {
      const progress = (goal.current / goal.target) * 100;
      if (progress >= 100) {
        acc.completedGoals.push(goal);
      } else {
        acc.inProgressGoals.push(goal);
      }
      return acc;
    },
    { completedGoals: [] as Goal[], inProgressGoals: [] as Goal[] }
  );

  return (
    <div className="main-scroll-area">
      <div className="bg-mindcally-dark-surface p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-medium text-white">Progress Report</h2>
          <div className="flex items-center space-x-2">
            <button
              onClick={handlePreviousMonth}
              className="p-1 hover:bg-mindcally-dark-background rounded text-mindcally-lime"
            >
              <ChevronLeft size={20} />
            </button>
            <span className="text-sm font-medium min-w-[100px] text-center text-white">
              {format(selectedMonth, 'MMMM yyyy')}
            </span>
            <button
              onClick={handleNextMonth}
              className={`p-1 hover:bg-mindcally-dark-background rounded text-mindcally-lime ${
                isAfter(addMonths(selectedMonth, 1), new Date()) ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              <ChevronRight size={20} />
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-8">
          <div className="bg-mindcally-dark-background p-4 rounded-lg">
            <h3 className="text-sm font-medium text-mindcally-lime mb-2">Habits Completed</h3>
            <div className="flex items-end justify-between">
              <span className="text-2xl font-bold text-white">{habitProgress}%</span>
              <div className="h-16 w-24 relative">
                <div 
                  className="absolute bottom-0 w-full bg-mindcally-lime rounded-t transition-all duration-300"
                  style={{ height: `${habitProgress}%` }}
                />
              </div>
            </div>
          </div>

          <div className="bg-mindcally-dark-background p-4 rounded-lg">
            <h3 className="text-sm font-medium text-mindcally-lime mb-2">Goals Progress</h3>
            <div className="flex items-end justify-between">
              <span className="text-2xl font-bold text-white">{goalProgressPercentage}%</span>
              <div className="h-16 w-24 relative">
                <div 
                  className="absolute bottom-0 w-full bg-mindcally-lime rounded-t transition-all duration-300"
                  style={{ height: `${goalProgressPercentage}%` }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium mb-4 text-white">Habits</h3>
            <div className="space-y-4">
              {habits.map(habit => (
                <HabitProgress
                  key={habit.id}
                  habit={habit}
                  history={habitHistory.filter(h => h.habitId === habit.id)}
                  selectedMonth={selectedMonth}
                />
              ))}
            </div>
          </div>

          {inProgressGoals.length > 0 && (
            <div>
              <h3 className="text-lg font-medium mb-4 text-white">Ultimate Goals</h3>
              <div className="space-y-4">
                {inProgressGoals.map(goal => {
                  const progress = Math.min((goal.current / goal.target) * 100, 100);

                  return (
                    <div key={goal.id} className="bg-mindcally-dark-surface rounded-lg p-4">
                      <div className="flex items-center justify-between mb-2">
                        <div>
                          <h4 className="font-medium text-white">{goal.name}</h4>
                          <p className="text-sm text-gray-400">Ultimate Goal</p>
                        </div>
                        <span className="text-sm font-medium text-mindcally-lime">
                          {goal.current} / {goal.target} days
                        </span>
                      </div>
                      <div className="h-2 bg-mindcally-dark-background rounded-full overflow-hidden">
                        <div
                          className="h-full bg-mindcally-lime transition-all duration-300"
                          style={{ width: `${progress}%` }}
                        />
                      </div>
                      {goal.deadline && (
                        <p className="text-sm text-gray-400 mt-2">
                          Deadline: {format(new Date(goal.deadline), 'MMM dd, yyyy')}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {completedGoals.length > 0 && (
            <div>
              <h3 className="text-lg font-medium mb-4 flex items-center gap-2">
                <Trophy className="text-mindcally-lime" size={24} />
                <span className="text-white">Completed Goals</span>
              </h3>
              <div className="space-y-4">
                {completedGoals.map(goal => {
                  return (
                    <div 
                      key={goal.id} 
                      className="bg-mindcally-dark-surface/50 rounded-lg p-4 opacity-75"
                    >
                      <div className="flex items-center justify-between mb-2">
                        <div>
                          <h4 className="font-medium text-white">{goal.name}</h4>
                          <p className="text-sm text-gray-400">Completed Goal</p>
                        </div>
                        <span className="text-sm font-medium text-mindcally-lime">
                          Completed!
                        </span>
                      </div>
                      <div className="h-2 bg-mindcally-dark-background rounded-full overflow-hidden">
                        <div
                          className="h-full bg-mindcally-lime"
                          style={{ width: '100%' }}
                        />
                      </div>
                      {goal.deadline && (
                        <p className="text-sm text-gray-400 mt-2">
                          Completed on: {format(new Date(goal.deadline), 'MMM dd, yyyy')}
                        </p>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};