import React, { useState, useCallback } from 'react';
import { Edit2, Plus, Info, ChevronRight, Check, Trophy, Trash2 } from 'lucide-react';
import { format, isToday, startOfTomorrow, differenceInMilliseconds, startOfMonth, endOfMonth, isWithinInterval } from 'date-fns';
import type { Goal, GoalProgress } from '../types';
import { Toast } from './Toast';
import { CheckInButton } from './CheckInButton';

interface GoalListProps {
  goals: Goal[];
  onEditGoal: (goal: Goal) => void;
  onDeleteGoal: (goalId: string) => void;
  onAddGoal: () => void;
  onToggleProgress: (goalId: string) => void;
  selectedDate: Date;
  goalProgress: GoalProgress[];
}

interface GoalDetailsModalProps {
  goal: Goal;
  onClose: () => void;
  onToggleProgress: (goalId: string) => void;
  isCompletedToday: boolean;
}

const GoalDetailsModal: React.FC<GoalDetailsModalProps> = ({ 
  goal, 
  onClose, 
  onToggleProgress,
  isCompletedToday 
}) => {
  const [showToast, setShowToast] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleProgress = async () => {
    if (isCompletedToday) {
      setShowToast(true);
      return;
    }

    setIsLoading(true);
    try {
      await onToggleProgress(goal.id);
      onClose();
    } catch (error) {
      console.error('Error updating progress:', error);
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  // Schedule reset at midnight
  React.useEffect(() => {
    if (isCompletedToday) {
      const tomorrow = startOfTomorrow();
      const timeUntilMidnight = differenceInMilliseconds(tomorrow, new Date());
      
      const resetTimer = setTimeout(() => {
        onClose();
      }, timeUntilMidnight);

      return () => clearTimeout(resetTimer);
    }
  }, [isCompletedToday, onClose]);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-mindcally-dark-surface rounded-xl w-full max-w-md p-6">
        <div className="space-y-4">
          <div className="flex justify-between items-start">
            <div className="flex items-center gap-3">
              <div className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300
                ${isCompletedToday
                  ? 'bg-mindcally-lime text-mindcally-navy'
                  : 'bg-gray-100 dark:bg-mindcally-dark-background'
                }`}
              >
                {isCompletedToday && <Check size={20} />}
              </div>
              <div>
                <h3 className="text-xl font-semibold text-mindcally-navy dark:text-white">{goal.name}</h3>
                <p className="text-sm text-gray-500">Ultimate Goal</p>
              </div>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
              <ChevronRight size={24} />
            </button>
          </div>

          <div className="pt-4">
            <div className="flex justify-between text-sm text-gray-600 mb-2">
              <span>Progress</span>
              <span>{Math.round((goal.current / goal.target) * 100)}%</span>
            </div>
            <div className="h-2 bg-gray-100 dark:bg-mindcally-dark-background rounded-full overflow-hidden">
              <div
                className="h-full bg-mindcally-lime transition-all duration-300"
                style={{ width: `${(goal.current / goal.target) * 100}%` }}
              />
            </div>
            <div className="mt-2 text-sm text-gray-600 dark:text-gray-400">
              {goal.current} of {goal.target} days completed
            </div>
          </div>

          <div className="pt-4">
            <h4 className="font-medium mb-2 text-mindcally-navy dark:text-white">Goal Details</h4>
            <div className="space-y-2 text-sm">
              <div className="flex justify-between">
                <span className="text-gray-500">Target Days</span>
                <span className="font-medium text-mindcally-navy dark:text-white">{goal.target} days</span>
              </div>
              {goal.deadline && (
                <div className="flex justify-between">
                  <span className="text-gray-500">Deadline</span>
                  <span className="font-medium text-mindcally-navy dark:text-white">
                    {format(new Date(goal.deadline), 'MMM dd, yyyy')}
                  </span>
                </div>
              )}
            </div>
          </div>

          <div
            className={`w-full mt-4 font-semibold py-3 rounded-lg flex items-center justify-center gap-2 transition-colors
              ${isCompletedToday || isLoading
                ? 'bg-gray-200 text-gray-500 dark:bg-mindcally-dark-background cursor-not-allowed'
                : 'bg-mindcally-lime text-mindcally-navy hover:bg-mindcally-light-hover cursor-pointer'
              }`}
            onClick={!isCompletedToday && !isLoading ? handleToggleProgress : undefined}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !isCompletedToday && !isLoading) {
                handleToggleProgress();
              }
            }}
          >
            {isLoading ? (
              <>
                <span className="animate-spin rounded-full h-5 w-5 border-b-2 border-current"></span>
                Processing...
              </>
            ) : (
              <>
                <div className={`w-6 h-6 rounded-full flex items-center justify-center transition-all duration-300
                  ${isCompletedToday
                    ? 'bg-mindcally-lime text-mindcally-navy'
                    : 'border-2 border-current'
                  }`}
                >
                  {isCompletedToday && <Check size={16} />}
                </div>
                {isCompletedToday ? 'Completed for Today' : 'Mark Today as Complete'}
              </>
            )}
          </div>
        </div>
      </div>

      {showToast && (
        <Toast
          message="You have already completed today's check-in"
          onClose={() => setShowToast(false)}
        />
      )}
    </div>
  );
};

export const GoalList: React.FC<GoalListProps> = ({ 
  goals, 
  onEditGoal, 
  onDeleteGoal,
  onAddGoal,
  onToggleProgress,
  selectedDate,
  goalProgress
}) => {
  const [selectedGoal, setSelectedGoal] = useState<Goal | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [loadingGoals, setLoadingGoals] = useState<Record<string, boolean>>({});

  // Calculate progress for the current month
  const calculateMonthlyProgress = (goal: Goal) => {
    const today = new Date();
    const monthStart = startOfMonth(today);
    const monthEnd = endOfMonth(today);
    
    const monthlyProgress = goalProgress.filter(progress => {
      const progressDate = new Date(progress.date);
      return isWithinInterval(progressDate, { start: monthStart, end: monthEnd }) &&
             progress.goalId === goal.id &&
             progress.completed;
    }).length;

    return monthlyProgress;
  };

  // Separate completed and in-progress goals
  const { completedGoals, inProgressGoals } = goals.reduce(
    (acc, goal) => {
      const monthlyProgress = calculateMonthlyProgress(goal);
      const progress = (monthlyProgress / goal.target) * 100;
      if (progress >= 100) {
        acc.completedGoals.push(goal);
      } else {
        acc.inProgressGoals.push(goal);
      }
      return acc;
    },
    { completedGoals: [] as Goal[], inProgressGoals: [] as Goal[] }
  );

  // Check if a goal is completed for today
  const isGoalCompletedForDate = useCallback((goalId: string, date: Date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const monthlyProgress = calculateMonthlyProgress(goals.find(g => g.id === goalId)!);
    return monthlyProgress >= 30 || goalProgress.some(
      progress => progress.goalId === goalId && 
                 progress.date === dateStr && 
                 progress.completed
    );
  }, [goalProgress, goals]);

  // Handle progress toggle with completion check
  const handleToggleProgress = async (goalId: string) => {
    const isCompleted = isGoalCompletedForDate(goalId, new Date());
    if (isCompleted) {
      setShowToast(true);
      return;
    }

    setLoadingGoals(prev => ({ ...prev, [goalId]: true }));
    try {
      await onToggleProgress(goalId);
    } catch (error) {
      console.error('Error updating progress:', error);
      setShowToast(true);
    } finally {
      setLoadingGoals(prev => ({ ...prev, [goalId]: false }));
    }
  };

  // Schedule reset at midnight for all completed goals
  React.useEffect(() => {
    const tomorrow = startOfTomorrow();
    const timeUntilMidnight = differenceInMilliseconds(tomorrow, new Date());
    
    const resetTimer = setTimeout(() => {
      setSelectedGoal(null);
      setLoadingGoals({});
    }, timeUntilMidnight);

    return () => clearTimeout(resetTimer);
  }, []);

  return (
    <div className="space-y-4">      
      {inProgressGoals.length > 0 && (
        <>
          <h2 className="text-xl font-semibold mb-4 text-mindcally-navy dark:text-white">
            Ultimate Goals
          </h2>
          
          <div className="space-y-4">
            {inProgressGoals.map((goal) => {
              const isCompleted = isGoalCompletedForDate(goal.id, selectedDate);
              const isLoading = loadingGoals[goal.id] || false;
              const monthlyProgress = calculateMonthlyProgress(goal);
              const progress = Math.round((goal.current / goal.target) * 100);
              
              return (
                <div 
                  key={goal.id} 
                  onClick={() => setSelectedGoal(goal)}
                  className={`bg-white dark:bg-mindcally-dark-surface p-4 rounded-xl shadow-sm space-y-2 cursor-pointer hover:shadow-md transition-all duration-300 ${
                    isCompleted || calculateMonthlyProgress(goal) >= goal.target ? 'border-l-4 border-mindcally-lime' : ''
                  }`}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex items-center gap-3">
                      <div onClick={(e) => {
                        e.stopPropagation();
                        if (!isCompleted && !isLoading && isToday(selectedDate)) {
                          handleToggleProgress(goal.id);
                        }
                      }}>
                        <CheckInButton
                          isCompleted={isCompleted}
                          isLoading={isLoading}
                          onClick={() => {}}
                          disabled={!isToday(selectedDate)}
                        />
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-mindcally-navy dark:text-white font-medium">{goal.name}</span>
                        {isCompleted && (
                          <div className="bg-mindcally-lime/10 text-mindcally-lime px-2 py-1 rounded-full text-xs font-medium">
                            Completed Today
                          </div>
                        )}
                        {calculateMonthlyProgress(goal) >= goal.target && !isCompleted && (
                          <div className="bg-mindcally-lime/10 text-mindcally-lime px-2 py-1 rounded-full text-xs font-medium">
                            Completed Today
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          onEditGoal(goal);
                        }}
                        className="text-gray-400 hover:text-mindcally-lime transition-colors p-1"
                      >
                        <Edit2 size={18} />
                      </button>
                      <button 
                        onClick={(e) => {
                          e.stopPropagation();
                          onDeleteGoal(goal.id);
                        }}
                        className="text-gray-400 hover:text-red-500 transition-colors p-1"
                      >
                        <Trash2 size={18} />
                      </button>
                      <Info size={18} className="text-gray-400" />
                    </div>
                  </div>

                  <div className="relative pt-1">
                    <div className="flex items-center justify-between">
                      {goal.deadline && (
                        <span className="text-xs text-gray-500">
                          Deadline: {format(new Date(goal.deadline), 'MMM dd, yyyy')}
                        </span>
                      )}
                      <div className="text-right">
                        <span className="text-xs font-semibold inline-block text-mindcally-navy dark:text-mindcally-lime">
                          {progress}%
                        </span>
                      </div>
                    </div>
                    <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-100 dark:bg-mindcally-dark-background mt-1">
                      <div
                        style={{ width: `${progress}%` }}
                        className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-mindcally-lime transition-all duration-300"
                      ></div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
      
      <button
        onClick={onAddGoal}
        className="w-full py-3 border-2 border-dashed border-mindcally-lime rounded-xl text-mindcally-navy dark:text-mindcally-lime hover:bg-mindcally-light-surface dark:hover:bg-mindcally-dark-surface transition-colors flex items-center justify-center gap-2"
      >
        <Plus size={20} />
        Add Ultimate Goal
      </button>

      {completedGoals.length > 0 && (
        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4 flex items-center gap-2 text-mindcally-navy dark:text-white">
            <Trophy className="text-mindcally-lime" size={24} />
            Completed Goals
          </h2>
          
          <div className="space-y-4">
            {completedGoals.map((goal) => (
              <div 
                key={goal.id}
                className="bg-white/50 dark:bg-mindcally-dark-surface/50 p-4 rounded-xl shadow-sm space-y-2 opacity-75"
              >
                <div className="flex justify-between items-center">
                  <div className="flex items-center gap-3">
                    <div className="w-6 h-6 rounded-full bg-mindcally-lime text-mindcally-navy flex items-center justify-center">
                      <Check size={16} />
                    </div>
                    <div>
                      <span className="text-mindcally-navy dark:text-white font-medium">{goal.name}</span>
                      <div className="text-xs text-gray-500">Completed Goal</div>
                    </div>
                  </div>
                  <span className="text-sm font-medium text-mindcally-lime">
                    Completed!
                  </span>
                </div>

                <div className="relative pt-1">
                  <div className="overflow-hidden h-2 text-xs flex rounded bg-gray-100 dark:bg-mindcally-dark-background mt-1">
                    <div
                      className="w-full bg-mindcally-lime"
                    ></div>
                  </div>
                  {goal.deadline && (
                    <span className="text-xs text-gray-500 mt-1 block">
                      Completed on: {format(new Date(goal.deadline), 'MMM dd, yyyy')}
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedGoal && (
        <GoalDetailsModal
          goal={selectedGoal}
          onClose={() => setSelectedGoal(null)}
          onToggleProgress={onToggleProgress}
          isCompletedToday={isGoalCompletedForDate(selectedGoal.id, new Date())}
        />
      )}

      {showToast && (
        <Toast
          message="You have already completed today's check-in"
          onClose={() => setShowToast(false)}
        />
      )}
    </div>
  );
};