import React from 'react';
import { format, isWithinInterval, startOfMonth, endOfMonth, isSameMonth } from 'date-fns';
import type { Habit, HabitCompletion } from '../types';

interface HabitProgressProps {
  habit: Habit;
  history: HabitCompletion[];
  selectedMonth: Date;
}

export const HabitProgress: React.FC<HabitProgressProps> = ({
  habit,
  history,
  selectedMonth,
}) => {
  const monthStart = startOfMonth(selectedMonth);
  const monthEnd = endOfMonth(selectedMonth);

  const calculateProgress = () => {
    const monthlyCompletions = history.filter(
      h => isWithinInterval(new Date(h.date), { start: monthStart, end: monthEnd }) && h.completed
    ).length;

    if (isSameMonth(new Date(), selectedMonth) && habit.completed) {
      return monthlyCompletions + 1;
    }

    return monthlyCompletions;
  };

  const completedDays = calculateProgress();
  const daysInMonth = isSameMonth(selectedMonth, new Date()) 
    ? new Date().getDate() 
    : endOfMonth(selectedMonth).getDate();
  const progress = Math.round((completedDays / daysInMonth) * 100);

  return (
    <div className="bg-mindcally-dark-surface rounded-lg p-4">
      <div className="flex items-center justify-between mb-2">
        <div>
          <h4 className="font-medium text-white">{habit.name}</h4>
          <p className="text-sm text-gray-400">
            {completedDays} days complete
          </p>
        </div>
        <span className={`text-sm font-medium ${
          progress >= 100 ? 'text-mindcally-lime' : 'text-gray-400'
        }`}>
          {progress}%
        </span>
      </div>
      <div className="h-2 bg-mindcally-dark-background rounded-full overflow-hidden">
        <div
          className="h-full bg-mindcally-lime transition-all duration-300"
          style={{ width: `${Math.min(progress, 100)}%` }}
        />
      </div>
      <p className="text-xs text-gray-400 mt-1">
        {progress >= 100 ? 'Completed!' : 'In progress'}
      </p>
    </div>
  );
};