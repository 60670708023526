import React, { useState, useEffect } from 'react';
import { Mail, Lock, UserCircle, Trash2, LogOut } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

interface SettingsViewProps {
  onBack: () => void;
  onLogout?: () => void;
}

export const SettingsView: React.FC<SettingsViewProps> = ({
  onLogout = () => {},
}) => {
  const { userProfile, currentUser } = useAuth();
  const [name, setName] = useState(userProfile?.name || '');
  const [email, setEmail] = useState(userProfile?.email || '');
  const [newsletter, setNewsletter] = useState(userProfile?.newsletter ?? true);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [passwordError, setPasswordError] = useState('');

  // Update form when userProfile changes
  useEffect(() => {
    if (userProfile) {
      setName(userProfile.name);
      setEmail(userProfile.email);
      setNewsletter(userProfile.newsletter ?? true);
    }
  }, [userProfile]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle profile update
  };

  const handlePasswordChange = (e: React.FormEvent) => {
    e.preventDefault();
    setPasswordError('');

    if (passwordForm.newPassword !== passwordForm.confirmPassword) {
      setPasswordError("New passwords don't match");
      return;
    }

    if (passwordForm.newPassword.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      return;
    }

    setShowPasswordModal(false);
    setPasswordForm({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };

  return (
    <div className="main-scroll-area">
      <div className="bg-mindcally-dark-surface p-6">
        <div className="space-y-6">
          <div>
            <h2 className="text-lg font-medium flex items-center gap-2 text-white">
              <UserCircle className="text-mindcally-lime" />
              Profile Settings
            </h2>
            
            <form onSubmit={handleSubmit} className="space-y-4 mt-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Name
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="w-full p-2 bg-mindcally-dark-background border border-mindcally-dark-surface rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime"
                  placeholder={currentUser?.displayName || 'Your name'}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="w-full p-2 bg-mindcally-dark-background border border-mindcally-dark-surface rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime"
                  placeholder={currentUser?.email || 'your@email.com'}
                />
              </div>

              <div>
                <button
                  type="button"
                  onClick={() => setShowPasswordModal(true)}
                  className="w-full p-2 border-2 border-mindcally-lime text-mindcally-lime rounded-lg hover:bg-mindcally-lime hover:text-mindcally-navy transition-colors flex items-center justify-center gap-2"
                >
                  <Lock size={18} />
                  Change Password
                </button>
              </div>

              <div className="flex items-center">
                <input
                  id="newsletter"
                  type="checkbox"
                  checked={newsletter}
                  onChange={(e) => setNewsletter(e.target.checked)}
                  className="h-4 w-4 text-mindcally-lime focus:ring-mindcally-lime border-mindcally-dark-surface rounded"
                />
                <label htmlFor="newsletter" className="ml-2 block text-sm text-gray-300">
                  Receive monthly newsletter with tips and updates
                </label>
              </div>

              <button
                type="submit"
                className="w-full bg-mindcally-lime text-mindcally-navy py-3 rounded-lg hover:bg-mindcally-light-hover transition-colors font-semibold"
              >
                Save Changes
              </button>
            </form>
          </div>

          <div className="pt-6 border-t border-mindcally-dark-surface space-y-4">
            <button
              onClick={onLogout}
              className="w-full flex items-center justify-center gap-2 py-3 bg-mindcally-dark-background text-mindcally-lime border-2 border-mindcally-lime rounded-lg hover:bg-mindcally-lime hover:text-mindcally-navy transition-colors font-semibold"
            >
              <LogOut size={20} />
              Log Out
            </button>

            <h2 className="text-lg font-medium text-red-400 flex items-center gap-2">
              <Trash2 className="text-red-400" />
              Danger Zone
            </h2>
            
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="w-full border-2 border-red-500 text-red-400 py-3 rounded-lg hover:bg-red-500/10 transition-colors"
            >
              Delete Account
            </button>
          </div>
        </div>
      </div>

      {/* Password Change Modal */}
      {showPasswordModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-mindcally-dark-surface rounded-xl w-full max-w-md p-6">
            <h2 className="text-xl font-semibold text-white mb-4">Change Password</h2>
            <form onSubmit={handlePasswordChange} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Current Password
                </label>
                <input
                  type="password"
                  value={passwordForm.currentPassword}
                  onChange={(e) =>
                    setPasswordForm((prev) => ({
                      ...prev,
                      currentPassword: e.target.value,
                    }))
                  }
                  className="w-full p-2 bg-mindcally-dark-background border border-mindcally-dark-surface rounded-lg text-white focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  New Password
                </label>
                <input
                  type="password"
                  value={passwordForm.newPassword}
                  onChange={(e) =>
                    setPasswordForm((prev) => ({
                      ...prev,
                      newPassword: e.target.value,
                    }))
                  }
                  className="w-full p-2 bg-mindcally-dark-background border border-mindcally-dark-surface rounded-lg text-white focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Confirm New Password
                </label>
                <input
                  type="password"
                  value={passwordForm.confirmPassword}
                  onChange={(e) =>
                    setPasswordForm((prev) => ({
                      ...prev,
                      confirmPassword: e.target.value,
                    }))
                  }
                  className="w-full p-2 bg-mindcally-dark-background border border-mindcally-dark-surface rounded-lg text-white focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime"
                />
              </div>

              {passwordError && (
                <p className="text-red-400 text-sm">{passwordError}</p>
              )}

              <div className="flex gap-4">
                <button
                  type="button"
                  onClick={() => setShowPasswordModal(false)}
                  className="flex-1 py-2 border border-mindcally-dark-surface text-white rounded-lg hover:bg-mindcally-dark-background"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="flex-1 py-2 bg-mindcally-lime text-mindcally-navy rounded-lg hover:bg-mindcally-light-hover font-semibold"
                >
                  Update Password
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Delete Account Confirmation Modal */}
      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-mindcally-dark-surface rounded-xl w-full max-w-md p-6">
            <h2 className="text-xl font-semibold text-white mb-4">Delete Account</h2>
            <p className="text-gray-300 mb-6">
              Are you sure you want to delete your account? This action cannot be undone.
            </p>
            <div className="flex gap-4">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="flex-1 py-2 border border-mindcally-dark-surface text-white rounded-lg hover:bg-mindcally-dark-background"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  // Handle account deletion
                  setShowDeleteConfirm(false);
                }}
                className="flex-1 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};