import type { Habit, Goal } from '../types';

interface CoachingState {
  step: 'initial' | 'askHabit' | 'askGoal' | 'describeGoal';
  lastResponse: string;
  shouldShowContinue: boolean;
}

let state: CoachingState = {
  step: 'initial',
  lastResponse: '',
  shouldShowContinue: false
};

const COACHING_RESPONSES = {
  INITIAL: "Hi, I'm MindCally your personal assistant. Shall we set a new daily habit? (Yes/No)",
  ASK_HABIT: "Please describe one habit in max 3 words (e.g. take a walk):",
  ASK_GOAL: "Would you like to set up your Ultimate Goal instead? (Yes/No)",
  DESCRIBE_GOAL: "Please describe your goal in max 3 words:",
  HABIT_LOGGED: "Your Daily Habit is now logged. Proceed to the Dashboard.",
  GOAL_LOGGED: "Your Goal is now logged. Proceed to the Dashboard.",
  PROCEED_DASHBOARD: "Let's proceed to the Dashboard where you can navigate the platform."
};

export const resetCoachingState = () => {
  state = {
    step: 'initial',
    lastResponse: '',
    shouldShowContinue: false
  };
};

export const getCoachingMessage = (
  userMessage: string
): { 
  message: string; 
  newHabit?: Omit<Habit, 'id' | 'completed'>;
  newGoal?: Omit<Goal, 'id' | 'current'>;
  showContinue?: boolean;
} => {
  const message = userMessage.toLowerCase().trim();
  
  if (!message) {
    return { message: COACHING_RESPONSES.INITIAL };
  }

  switch (state.step) {
    case 'initial':
      if (message === 'yes') {
        state.step = 'askHabit';
        return { message: COACHING_RESPONSES.ASK_HABIT };
      } else if (message === 'no') {
        state.step = 'askGoal';
        return { message: COACHING_RESPONSES.ASK_GOAL };
      }
      return { message: COACHING_RESPONSES.INITIAL };

    case 'askHabit':
      if (message.split(' ').length <= 3) {
        state.lastResponse = message;
        state.shouldShowContinue = true;
        return { 
          message: COACHING_RESPONSES.HABIT_LOGGED,
          newHabit: {
            name: message,
            type: 'daily',
            frequency: 1
          },
          showContinue: true
        };
      }
      return { message: "Please keep your habit description to 3 words or less:" };

    case 'askGoal':
      if (message === 'yes') {
        state.step = 'describeGoal';
        return { message: COACHING_RESPONSES.DESCRIBE_GOAL };
      } else if (message === 'no') {
        state.shouldShowContinue = true;
        return { 
          message: COACHING_RESPONSES.PROCEED_DASHBOARD,
          showContinue: true
        };
      }
      return { message: COACHING_RESPONSES.ASK_GOAL };

    case 'describeGoal':
      if (message.split(' ').length <= 3) {
        state.lastResponse = message;
        state.shouldShowContinue = true;
        const today = new Date();
        const deadline = new Date();
        deadline.setDate(today.getDate() + 30); // Default 30-day deadline
        
        return { 
          message: COACHING_RESPONSES.GOAL_LOGGED,
          newGoal: {
            name: message,
            type: 'Ultimate',
            target: 30,
            deadline: deadline.toISOString().split('T')[0]
          },
          showContinue: true
        };
      }
      return { message: "Please keep your goal description to 3 words or less:" };

    default:
      return { message: COACHING_RESPONSES.PROCEED_DASHBOARD };
  }
};