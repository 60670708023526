import { db } from '../lib/firebase';
import { 
  collection, 
  doc, 
  setDoc, 
  getDoc, 
  updateDoc, 
  deleteDoc, 
  query, 
  where, 
  getDocs 
} from 'firebase/firestore';
import type { Habit, Goal, HabitCompletion, GoalProgress } from '../types';

interface UserProfile {
  name: string;
  email: string;
  createdAt: string;
}

export class FirestoreService {
  private static instance: FirestoreService;
  
  private constructor() {}

  public static getInstance(): FirestoreService {
    if (!FirestoreService.instance) {
      FirestoreService.instance = new FirestoreService();
    }
    return FirestoreService.instance;
  }

  // User Profile Methods
  async saveUserProfile(userId: string, profile: UserProfile) {
    const userRef = doc(db, 'users', userId);
    await setDoc(userRef, profile, { merge: true });
  }

  async getUserProfile(userId: string) {
    const userRef = doc(db, 'users', userId);
    const docSnap = await getDoc(userRef);
    return docSnap.exists() ? docSnap.data() as UserProfile : null;
  }

  async updateUserProfile(userId: string, updates: Partial<UserProfile>) {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, updates);
  }

  // Habits Methods
  async saveHabit(userId: string, habit: Habit) {
    const habitRef = doc(db, 'users', userId, 'habits', habit.id);
    await setDoc(habitRef, habit);
  }

  async getHabits(userId: string): Promise<Habit[]> {
    const habitsRef = collection(db, 'users', userId, 'habits');
    const querySnapshot = await getDocs(habitsRef);
    return querySnapshot.docs.map(doc => doc.data() as Habit);
  }

  async updateHabit(userId: string, habitId: string, updates: Partial<Habit>) {
    const habitRef = doc(db, 'users', userId, 'habits', habitId);
    await updateDoc(habitRef, updates);
  }

  async deleteHabit(userId: string, habitId: string) {
    const habitRef = doc(db, 'users', userId, 'habits', habitId);
    await deleteDoc(habitRef);
  }

  // Goals Methods
  async saveGoal(userId: string, goal: Goal) {
    const goalRef = doc(db, 'users', userId, 'goals', goal.id);
    await setDoc(goalRef, goal);
  }

  async getGoals(userId: string): Promise<Goal[]> {
    const goalsRef = collection(db, 'users', userId, 'goals');
    const querySnapshot = await getDocs(goalsRef);
    return querySnapshot.docs.map(doc => doc.data() as Goal);
  }

  async updateGoal(userId: string, goalId: string, updates: Partial<Goal>) {
    const goalRef = doc(db, 'users', userId, 'goals', goalId);
    await updateDoc(goalRef, updates);
  }

  async deleteGoal(userId: string, goalId: string) {
    const goalRef = doc(db, 'users', userId, 'goals', goalId);
    await deleteDoc(goalRef);
  }

  // Progress Methods
  async saveHabitCompletion(userId: string, completion: HabitCompletion) {
    const completionRef = doc(db, 'users', userId, 'habitCompletions', `${completion.habitId}_${completion.date}`);
    await setDoc(completionRef, completion);
  }

  async getHabitCompletions(userId: string, habitId: string): Promise<HabitCompletion[]> {
    const completionsRef = collection(db, 'users', userId, 'habitCompletions');
    const q = query(completionsRef, where('habitId', '==', habitId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => doc.data() as HabitCompletion);
  }

  async saveGoalProgress(userId: string, progress: GoalProgress) {
    const progressRef = doc(db, 'users', userId, 'goalProgress', `${progress.goalId}_${progress.date}`);
    await setDoc(progressRef, progress);
  }

  async getGoalProgress(userId: string, goalId: string): Promise<GoalProgress[]> {
    const progressRef = collection(db, 'users', userId, 'goalProgress');
    const q = query(progressRef, where('goalId', '==', goalId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => doc.data() as GoalProgress);
  }
}