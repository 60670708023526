import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { Habit, HabitType } from '../types';

interface NewHabitModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (habit: Omit<Habit, 'id' | 'completed'>) => void;
}

export const NewHabitModal: React.FC<NewHabitModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [habitName, setHabitName] = useState('');
  const [type, setType] = useState<HabitType>('daily');
  const [frequency, setFrequency] = useState('1');

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      name: habitName,
      type,
      frequency: parseInt(frequency, 10),
    });
    setHabitName('');
    setType('daily');
    setFrequency('1');
  };

  return (
    <div className="fixed inset-0 bg-mindcally-navy bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-mindcally-dark-surface rounded-xl w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-white">Create New Habit</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-mindcally-lime">
            <X size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Habit Name
            </label>
            <input
              type="text"
              value={habitName}
              onChange={(e) => setHabitName(e.target.value)}
              className="w-full p-2 bg-mindcally-dark-background border border-mindcally-dark-surface rounded-lg text-white placeholder-gray-400 focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime"
              placeholder="e.g., Morning Meditation"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Type
            </label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value as HabitType)}
              className="w-full p-2 bg-mindcally-dark-background border border-mindcally-dark-surface rounded-lg text-white focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime"
            >
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-300 mb-1">
              Frequency
            </label>
            <select
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
              className="w-full p-2 bg-mindcally-dark-background border border-mindcally-dark-surface rounded-lg text-white focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime"
            >
              <option value="1">1 time per {type}</option>
              <option value="2">2 times per {type}</option>
              <option value="3">3 times per {type}</option>
              <option value="5">5 times per {type}</option>
              <option value="7">7 times per {type}</option>
            </select>
          </div>

          <button
            type="submit"
            className="w-full bg-mindcally-lime text-mindcally-navy py-3 rounded-lg hover:bg-mindcally-light-hover transition-colors font-semibold"
          >
            Create Habit
          </button>
        </form>
      </div>
    </div>
  );
};