import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut as firebaseSignOut,
  onAuthStateChanged,
  updateProfile,
  User,
  AuthError 
} from 'firebase/auth';
import { auth } from '../lib/firebase';
import { FirestoreService } from '../services/FirestoreService';

interface UserProfile {
  name: string;
  email: string;
  newsletter?: boolean;
}

interface AuthContextType {
  currentUser: User | null;
  userProfile: UserProfile | null;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signOut: () => Promise<void>;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);
  const firestoreService = FirestoreService.getInstance();

  const handleAuthError = (error: AuthError) => {
    console.error('Authentication error:', error);
    
    switch (error.code) {
      case 'auth/email-already-in-use':
        throw new Error('This email is already registered. Please sign in instead.');
      case 'auth/wrong-password':
        throw new Error('Invalid email or password.');
      case 'auth/user-not-found':
        throw new Error('No account found with this email. Please sign up.');
      case 'auth/invalid-credential':
        throw new Error('Invalid email or password.');
      case 'auth/too-many-requests':
        throw new Error('Too many attempts. Please try again later.');
      default:
        throw new Error('An error occurred. Please try again.');
    }
  };

  const loadUserProfile = async (user: User) => {
    try {
      const profile = await firestoreService.getUserProfile(user.uid);
      if (profile) {
        setUserProfile({
          name: profile.name || user.displayName || '',
          email: profile.email || user.email || '',
          newsletter: profile.newsletter ?? true
        });
      } else {
        // If no profile exists, create one with default values
        const defaultProfile = {
          name: user.displayName || '',
          email: user.email || '',
          newsletter: true,
          createdAt: new Date().toISOString()
        };
        await firestoreService.saveUserProfile(user.uid, defaultProfile);
        setUserProfile(defaultProfile);
      }
    } catch (error) {
      console.error('Error loading user profile:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setCurrentUser(user);
      if (user) {
        await loadUserProfile(user);
      } else {
        setUserProfile(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const signUp = async (email: string, password: string, name: string) => {
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      
      // Update user profile with name
      await updateProfile(user, { displayName: name });
      
      // Create user profile in Firestore
      const userProfile = {
        name,
        email,
        newsletter: true,
        createdAt: new Date().toISOString()
      };
      
      await firestoreService.saveUserProfile(user.uid, userProfile);
      setUserProfile(userProfile);
      
    } catch (error) {
      handleAuthError(error as AuthError);
    }
  };

  const signIn = async (email: string, password: string) => {
    try {
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      await loadUserProfile(user);
    } catch (error) {
      handleAuthError(error as AuthError);
    }
  };

  const signOut = async () => {
    try {
      await firebaseSignOut(auth);
      setUserProfile(null);
      window.location.reload(); // Force reload to clear any cached state
    } catch (error) {
      throw new Error('Failed to sign out. Please try again.');
    }
  };

  const value = {
    currentUser,
    userProfile,
    signUp,
    signIn,
    signOut,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};