import React, { useState } from 'react';
import { Check, Bell, BellRing, Trash2, Plus } from 'lucide-react';
import type { Habit } from '../types';

interface HabitListProps {
  habits: Habit[];
  onToggle: (id: string) => void;
  onRemove: (id: string) => void;
  onAdd: () => void;
  onSetReminder: (habitId: string, time: string) => void;
}

interface ReminderModalProps {
  isOpen: boolean;
  onClose: () => void;
  habit: Habit;
  onSetReminder: (habitId: string, time: string) => void;
}

const ReminderModal: React.FC<ReminderModalProps> = ({ isOpen, onClose, habit, onSetReminder }) => {
  const [time, setTime] = useState(habit?.reminderTime || '09:00');

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSetReminder(habit.id, time);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-mindcally-dark-surface rounded-xl w-full max-w-sm p-6">
        <h3 className="text-lg font-semibold mb-4 text-mindcally-navy dark:text-white">
          Set Reminder for {habit.name}
        </h3>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Reminder Time
            </label>
            <input
              type="time"
              value={time}
              onChange={(e) => setTime(e.target.value)}
              className="w-full p-2 border rounded-lg focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime dark:bg-mindcally-dark-background dark:border-mindcally-dark-surface dark:text-white"
            />
          </div>
          <div className="flex gap-2">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 px-4 py-2 border border-gray-300 dark:border-mindcally-dark-surface rounded-lg hover:bg-mindcally-light-surface dark:hover:bg-mindcally-dark-background text-mindcally-navy dark:text-white"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="flex-1 px-4 py-2 bg-mindcally-lime text-mindcally-navy font-semibold rounded-lg hover:bg-mindcally-light-hover"
            >
              Set Reminder
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export const HabitList: React.FC<HabitListProps> = ({ 
  habits, 
  onToggle, 
  onRemove, 
  onAdd,
  onSetReminder 
}) => {
  const [selectedHabit, setSelectedHabit] = useState<Habit | null>(null);

  return (
    <div className="space-y-4">      
      {habits.map((habit) => (
        <div
          key={habit.id}
          className="flex items-center justify-between p-4 bg-white dark:bg-mindcally-dark-surface rounded-xl shadow-sm hover:shadow-md transition-shadow"
        >
          <div className="flex items-center gap-3">
            <button
              onClick={() => onToggle(habit.id)}
              className={`w-6 h-6 rounded-full flex items-center justify-center transition-colors
                ${
                  habit.completed
                    ? 'bg-mindcally-lime text-mindcally-navy'
                    : 'border-2 border-gray-300 dark:border-gray-600 hover:border-mindcally-lime'
                }`}
            >
              {habit.completed && <Check size={16} />}
            </button>
            <div>
              <span className="font-medium text-mindcally-navy dark:text-white">{habit.name}</span>
              <span className="text-xs text-gray-500 dark:text-gray-400 block">Daily</span>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <button 
              onClick={() => setSelectedHabit(habit)}
              className={`transition-colors ${
                habit.reminderTime 
                  ? 'text-mindcally-lime hover:text-mindcally-light-hover' 
                  : 'text-gray-400 dark:text-gray-500 hover:text-gray-600 dark:hover:text-gray-400'
              }`}
              title={habit.reminderTime ? `Reminder set for ${habit.reminderTime}` : 'Set reminder'}
            >
              {habit.reminderTime ? <BellRing size={20} /> : <Bell size={20} />}
            </button>
            <button 
              onClick={() => onRemove(habit.id)}
              className="text-gray-400 dark:text-gray-500 hover:text-red-500 dark:hover:text-red-400 transition-colors"
            >
              <Trash2 size={20} />
            </button>
          </div>
        </div>
      ))}

      <button
        onClick={onAdd}
        className="w-full flex items-center justify-center gap-2 p-4 bg-white dark:bg-mindcally-dark-surface rounded-xl shadow-sm hover:shadow-md transition-all border-2 border-dashed border-mindcally-lime text-mindcally-navy dark:text-white hover:bg-mindcally-light-surface dark:hover:bg-mindcally-dark-background"
      >
        <Plus size={20} />
        Add New Habit
      </button>

      {selectedHabit && (
        <ReminderModal
          isOpen={selectedHabit !== null}
          onClose={() => setSelectedHabit(null)}
          habit={selectedHabit}
          onSetReminder={onSetReminder}
        />
      )}
    </div>
  );
};