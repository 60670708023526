import React from 'react';
import { Play } from 'lucide-react';

interface Video {
  id: string;
  title: string;
  description: string;
  type: 'youtube' | 'drive';
}

export const PauseView: React.FC = () => {
  const videos: Video[] = [
    {
      id: '1Aynq-14RQrmpoPgYJQfre2sFQwwedCDp',
      title: 'Morning Meditation',
      description: 'Start your day with this calming morning meditation practice.',
      type: 'drive'
    },
    {
      id: '1pnAk5yKa4rhThVinsf7LkC3Uf_dDR5i5',
      title: 'Evening Meditation',
      description: 'Wind down your day with this peaceful evening meditation practice.',
      type: 'drive'
    },
    {
      id: '1kDFwh2uWE9OrVA2hWBetgXkw6W3gVIE2',
      title: 'Midday Meditation',
      description: 'Take a mindful break during your day with this centering meditation practice.',
      type: 'drive'
    },
    {
      id: '1Vna-B4q8rFGbEB2VaJd-zNn4u62o5GNd',
      title: 'Breathwork Exercise',
      description: 'A guided breathwork session to help you find balance and reduce stress.',
      type: 'drive'
    },
    {
      id: '-X51SK1WEoE',
      title: 'Mindfulness Meditation',
      description: 'A guided meditation session to help you relax, focus, and stay present in the moment.',
      type: 'youtube'
    },
    {
      id: 'uxjh2V1zsrA',
      title: 'Deep Sleep Meditation',
      description: 'A calming meditation to help you achieve deep, restful sleep.',
      type: 'youtube'
    },
    {
      id: '5Gy2DzXLhgQ',
      title: 'Stress Relief Meditation',
      description: 'A meditation practice designed to help reduce stress and anxiety.',
      type: 'youtube'
    }
  ];

  return (
    <div className="main-scroll-area">
      <div className="bg-mindcally-dark-surface p-6">
        <div className="flex items-center gap-3 mb-6">
          <Play className="text-mindcally-lime" size={24} />
          <h1 className="text-xl font-semibold text-white">Pause & Reflect</h1>
        </div>

        <div className="space-y-6">
          {videos.map((video) => (
            <div 
              key={video.id} 
              className="bg-mindcally-dark-background rounded-xl overflow-hidden"
            >
              <div className="relative pb-[56.25%] h-0">
                <iframe
                  className="absolute top-0 left-0 w-full h-full"
                  src={video.type === 'youtube' 
                    ? `https://www.youtube.com/embed/${video.id}`
                    : `https://drive.google.com/file/d/${video.id}/preview`
                  }
                  title={video.title}
                  frameBorder="0"
                  allow={video.type === 'youtube'
                    ? "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    : "autoplay; fullscreen; picture-in-picture"
                  }
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="strict-origin"
                />
              </div>
              <div className="p-4">
                <h3 className="text-lg font-medium text-white mb-2">
                  {video.title}
                </h3>
                <p className="text-sm text-gray-400">
                  {video.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};