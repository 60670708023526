import React, { useState, useEffect } from 'react';
import { AuthPages } from './components/AuthPages';
import { Navbar } from './components/Navbar';
import { Banner } from './components/Banner';
import { DateStrip } from './components/DateStrip';
import { HabitList } from './components/HabitList';
import { GoalList } from './components/GoalList';
import { GoalModal } from './components/GoalModal';
import { NewHabitModal } from './components/NewHabitModal';
import { StatsView } from './components/StatsView';
import { PauseView } from './components/PauseView';
import { SettingsView } from './components/SettingsView';
import { SpacesView } from './components/SpacesView';
import { Coach } from './components/Coach';
import { Toast } from './components/Toast';
import { useAuth } from './contexts/AuthContext';
import { FirestoreService } from './services/FirestoreService';
import type { Habit, Goal, HabitCompletion, GoalProgress } from './types';

export const App: React.FC = () => {
  const { currentUser, userProfile, signOut } = useAuth();
  const [showCoach, setShowCoach] = useState(true);
  const [habits, setHabits] = useState<Habit[]>([]);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentView, setCurrentView] = useState<ViewType>('home');
  const [showNewHabitModal, setShowNewHabitModal] = useState(false);
  const [showGoalModal, setShowGoalModal] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [habitHistory] = useState<HabitCompletion[]>([]);
  const [goalProgress] = useState<GoalProgress[]>([]);

  const firestoreService = FirestoreService.getInstance();

  // Load user data when authenticated
  useEffect(() => {
    const loadUserData = async () => {
      if (currentUser) {
        try {
          const userHabits = await firestoreService.getHabits(currentUser.uid);
          setHabits(userHabits);

          const userGoals = await firestoreService.getGoals(currentUser.uid);
          setGoals(userGoals);
        } catch (error) {
          console.error('Error loading user data:', error);
          setShowToast(true);
          setToastMessage('Failed to load your data. Please try again later.');
        }
      }
    };

    loadUserData();
  }, [currentUser]);

  const handleAddHabit = async (habit: Omit<Habit, 'id' | 'completed'>) => {
    if (!currentUser) return;

    const newHabit: Habit = {
      ...habit,
      id: Date.now().toString(),
      completed: false
    };

    try {
      await firestoreService.saveHabit(currentUser.uid, newHabit);
      setHabits(prev => [...prev, newHabit]);
      setShowToast(true);
      setToastMessage(`New habit "${habit.name}" created!`);
    } catch (error) {
      console.error('Error saving habit:', error);
      setShowToast(true);
      setToastMessage('Failed to save habit. Please try again.');
    }
  };

  const handleAddGoal = async (goal: Omit<Goal, 'id' | 'current'>) => {
    if (!currentUser) return;

    const newGoal: Goal = {
      ...goal,
      id: Date.now().toString(),
      current: 0
    };

    try {
      await firestoreService.saveGoal(currentUser.uid, newGoal);
      setGoals(prev => [...prev, newGoal]);
      setShowToast(true);
      setToastMessage(`New goal "${goal.name}" created!`);
    } catch (error) {
      console.error('Error saving goal:', error);
      setShowToast(true);
      setToastMessage('Failed to save goal. Please try again.');
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
      setShowCoach(true);
      setHabits([]);
      setGoals([]);
    } catch (error) {
      console.error('Error signing out:', error);
      setShowToast(true);
      setToastMessage('Failed to sign out. Please try again.');
    }
  };

  if (!currentUser || !userProfile) {
    return <AuthPages />;
  }

  const completionRate = habits.length > 0
    ? Math.round((habits.filter(h => h.completed).length / habits.length) * 100)
    : 0;

  if (showCoach) {
    return (
      <Coach
        habits={habits}
        goals={goals}
        userName={userProfile.name}
        onContinue={() => setShowCoach(false)}
        onAddHabit={handleAddHabit}
        onAddGoal={handleAddGoal}
      />
    );
  }

  return (
    <div className="app-container">
      <Navbar
        currentView={currentView}
        onViewChange={setCurrentView}
      />

      {currentView === 'home' && (
        <div className="main-scroll-area">
          <Banner
            userName={userProfile.name}
            completionRate={completionRate}
            totalHabits={habits.length}
            completedHabits={habits.filter(h => h.completed).length}
          />

          <div className="p-4 space-y-6">
            <DateStrip
              selectedDate={selectedDate}
              onDateSelect={setSelectedDate}
            />

            <div className="space-y-6">
              <section>
                <h2 className="text-xl font-semibold mb-4 text-mindcally-navy dark:text-white">
                  Daily Habits
                </h2>
                <HabitList
                  habits={habits}
                  onToggle={async (id) => {
                    if (!currentUser) return;
                    const updatedHabits = habits.map(h =>
                      h.id === id ? { ...h, completed: !h.completed } : h
                    );
                    const updatedHabit = updatedHabits.find(h => h.id === id);
                    if (updatedHabit) {
                      try {
                        await firestoreService.updateHabit(currentUser.uid, id, {
                          completed: updatedHabit.completed
                        });
                        setHabits(updatedHabits);
                      } catch (error) {
                        console.error('Error updating habit:', error);
                        setShowToast(true);
                        setToastMessage('Failed to update habit. Please try again.');
                      }
                    }
                  }}
                  onRemove={async (id) => {
                    if (!currentUser) return;
                    try {
                      await firestoreService.deleteHabit(currentUser.uid, id);
                      setHabits(prev => prev.filter(h => h.id !== id));
                      setShowToast(true);
                      setToastMessage('Habit removed successfully');
                    } catch (error) {
                      console.error('Error removing habit:', error);
                      setShowToast(true);
                      setToastMessage('Failed to remove habit. Please try again.');
                    }
                  }}
                  onAdd={() => setShowNewHabitModal(true)}
                  onSetReminder={async (habitId, time) => {
                    if (!currentUser) return;
                    try {
                      await firestoreService.updateHabit(currentUser.uid, habitId, {
                        reminderTime: time
                      });
                      setHabits(prev =>
                        prev.map(h =>
                          h.id === habitId ? { ...h, reminderTime: time } : h
                        )
                      );
                      setShowToast(true);
                      setToastMessage('Reminder set successfully');
                    } catch (error) {
                      console.error('Error setting reminder:', error);
                      setShowToast(true);
                      setToastMessage('Failed to set reminder. Please try again.');
                    }
                  }}
                />
              </section>

              <section>
                <GoalList
                  goals={goals}
                  onEditGoal={(goal) => {
                    setShowGoalModal(true);
                  }}
                  onDeleteGoal={async (goalId) => {
                    if (!currentUser) return;
                    try {
                      await firestoreService.deleteGoal(currentUser.uid, goalId);
                      setGoals(prev => prev.filter(g => g.id !== goalId));
                      setShowToast(true);
                      setToastMessage('Goal removed successfully');
                    } catch (error) {
                      console.error('Error removing goal:', error);
                      setShowToast(true);
                      setToastMessage('Failed to remove goal. Please try again.');
                    }
                  }}
                  onAddGoal={() => setShowGoalModal(true)}
                  onToggleProgress={async (goalId) => {
                    if (!currentUser) return;
                    const updatedGoals = goals.map(g =>
                      g.id === goalId
                        ? { ...g, current: Math.min(g.current + 1, g.target) }
                        : g
                    );
                    const updatedGoal = updatedGoals.find(g => g.id === goalId);
                    if (updatedGoal) {
                      try {
                        await firestoreService.updateGoal(currentUser.uid, goalId, {
                          current: updatedGoal.current
                        });
                        setGoals(updatedGoals);
                      } catch (error) {
                        console.error('Error updating goal:', error);
                        setShowToast(true);
                        setToastMessage('Failed to update goal. Please try again.');
                      }
                    }
                  }}
                  selectedDate={selectedDate}
                  goalProgress={goalProgress}
                />
              </section>
            </div>
          </div>
        </div>
      )}

      {currentView === 'stats' && (
        <StatsView
          habits={habits}
          habitHistory={habitHistory}
          goals={goals}
          goalProgress={goalProgress}
          onBack={() => setCurrentView('home')}
        />
      )}

      {currentView === 'pause' && (
        <PauseView />
      )}
      
      {currentView === 'spaces' && (
        <SpacesView />
      )}

      {currentView === 'settings' && (
        <SettingsView
          onBack={() => setCurrentView('home')}
          onLogout={handleLogout}
        />
      )}

      {showNewHabitModal && (
        <NewHabitModal
          isOpen={showNewHabitModal}
          onClose={() => setShowNewHabitModal(false)}
          onSubmit={(habit) => {
            handleAddHabit(habit);
            setShowNewHabitModal(false);
          }}
        />
      )}

      {showGoalModal && (
        <GoalModal
          isOpen={showGoalModal}
          onClose={() => setShowGoalModal(false)}
          onSubmit={(goal) => {
            handleAddGoal(goal);
            setShowGoalModal(false);
          }}
        />
      )}

      {showToast && (
        <Toast
          message={toastMessage}
          onClose={() => setShowToast(false)}
        />
      )}
    </div>
  );
};