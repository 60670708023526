import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import type { Goal, GoalModalProps } from '../types';

export const GoalModal: React.FC<GoalModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  initialGoal,
}) => {
  const [goalName, setGoalName] = useState('');
  const [deadline, setDeadline] = useState('');

  useEffect(() => {
    if (initialGoal) {
      setGoalName(initialGoal.name);
      setDeadline(initialGoal.deadline || '');
    }
  }, [initialGoal]);

  if (!isOpen) return null;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    // Calculate target days based on deadline
    const today = new Date();
    const deadlineDate = new Date(deadline);
    const diffTime = Math.abs(deadlineDate.getTime() - today.getTime());
    const targetDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    onSubmit({
      name: goalName,
      target: targetDays,
      deadline: deadline,
      type: 'Ultimate'
    });
    
    setGoalName('');
    setDeadline('');
    onClose();
  };

  // Calculate minimum date (today)
  const today = new Date().toISOString().split('T')[0];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white dark:bg-mindcally-dark-surface rounded-xl w-full max-w-md p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-mindcally-navy dark:text-white">
            {initialGoal ? 'Edit Ultimate Goal' : 'Create Ultimate Goal'}
          </h2>
          <button onClick={onClose} className="text-gray-500 dark:text-gray-400">
            <X size={24} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Describe your Ultimate Goal
            </label>
            <input
              type="text"
              value={goalName}
              onChange={(e) => setGoalName(e.target.value)}
              className="w-full p-2 border rounded-lg bg-white dark:bg-mindcally-dark-background text-mindcally-navy dark:text-white border-gray-300 dark:border-mindcally-dark-surface focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime"
              placeholder="e.g., Read 5 books"
              required
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
              Your Deadline for this Goal
            </label>
            <input
              type="date"
              value={deadline}
              onChange={(e) => setDeadline(e.target.value)}
              min={today}
              className="w-full p-2 border rounded-lg bg-white dark:bg-mindcally-dark-background text-mindcally-navy dark:text-white border-gray-300 dark:border-mindcally-dark-surface focus:ring-2 focus:ring-mindcally-lime focus:border-mindcally-lime"
              required
            />
          </div>

          <button
            type="submit"
            className="w-full bg-mindcally-lime text-mindcally-navy py-3 rounded-lg hover:bg-mindcally-light-hover transition-colors font-semibold"
          >
            {initialGoal ? 'Update Goal' : 'Create Goal'}
          </button>
        </form>
      </div>
    </div>
  );
};