import React from 'react';
import Cal, { getCalApi } from "@calcom/embed-react";
import { Users } from 'lucide-react';

export const SpacesView: React.FC = () => {
  React.useEffect(() => {
    (async function () {
      const cal = await getCalApi({ namespace: "talk-space" });
      cal("ui", {
        hideEventTypeDetails: false,
        layout: "month_view",
        styles: {
          branding: {
            brandColor: "#D6FF00"
          },
          enabledDateButton: {
            backgroundColor: "#D6FF00",
            color: "#0A0A4F"
          },
          selectedDateButton: {
            backgroundColor: "#D6FF00",
            color: "#0A0A4F"
          },
          submitButton: {
            backgroundColor: "#D6FF00",
            color: "#0A0A4F"
          }
        }
      });
    })();
  }, []);

  return (
    <div className="main-scroll-area">
      <div className="bg-mindcally-dark-surface p-6">
        <div className="flex items-center gap-3 mb-6">
          <Users className="text-mindcally-lime" size={24} />
          <h1 className="text-xl font-semibold text-white">Weekly Talk Spaces</h1>
        </div>

        <p className="text-gray-300 mb-8">
          Join fellow entrepreneurs each week for meaningful, authentic conversations 
          about the entrepreneurial journey. Explore the power of connection and 
          support one another in growth and success.
        </p>

        <div className="bg-mindcally-dark-background rounded-xl overflow-hidden">
          <Cal
            namespace="talk-space"
            calLink="mindcally/talk-space"
            style={{
              width: "100%",
              height: "100vh",
              overflow: "scroll",
              "--cal-brand-color": "#D6FF00",
              "--cal-text-color": "#FFFFFF",
              "--cal-text-emphasis-color": "#D6FF00"
            }}
            config={{
              layout: "month_view",
              theme: "dark"
            }}
          />
        </div>
      </div>
    </div>
  );
};